import React from "react";
import ParticlesBg from "particles-bg";
import framer from "framer-motion";
import { useState, useEffect, useRef } from "react";
import { Interface } from "readline";
import Header from "./assets/header.tsx";

function FirstPage({ page }) {
  const [color, setcolor] = useState("wheat");
  const changePage = (data: string) => {
    page(data);
  };

  return (
    <>
      <Header page={page} />
      <div className="home cover">
        <main className="main" id="main">
          <div className="title" id="title">
            <h1 className="ext" id="ext">
              FULLSTACK
            </h1>
            <h2 className="mext" id="mext">
              <span className="inv" style={{ color: color }}>
                JAVASCRIPT
              </span>{" "}
              DEVELOPER
            </h2>
            <p className="text">
              Tech enthusiast, volunteer, freelancer, Engineer.
            </p>
            <div className="btns">
              <div className="btn_cover">
                <button
                  className="btn"
                  id="hire"
                  onClick={() => changePage("contact")}
                >
                  Hire me ..
                </button>
              </div>

              <div className="btn_cover">
                <button
                  className="btn"
                  id="next"
                  onClick={() => changePage("about")}
                >
                  Next ..
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default FirstPage;
