import * as React from "react";
let check = true;

function Header({ page }: { page: any }) {
  const [visible, setVisible] = React.useState("invisible");
  const [link, setLink] = React.useState("link");
  const changePage = (data: string) => {
    if (check === false) check = true;
    page(data);
  };
  const side = () => {
    if (check === true) {
      setVisible("visible");
      setLink("link");
      check = false;
    }
  };

  const noside = () => {
    console.log(check);
    if (check === false) {
      setVisible("invisible");
      setLink("nolink");
      console.log("nodide");
      check = true;
    }
  };
  return (
    <header className="header" id="header">
      <h4 className="logo" id="logo">
        {"" + "<Chinedu_Mbaodoh/>"}
      </h4>
      <nav className="nav">
        <button className="link" onClick={() => changePage("home")}>
          home. ?
        </button>
        <button className="link" onClick={() => changePage("works")}>
          project. ?
        </button>
        <button className="link" onClick={() => changePage("contact")}>
          contact. ?
        </button>
        <button className="link" onClick={() => changePage("about")}>
          about. ?
        </button>
      </nav>
      <div onClick={side} className="hamburger">
        <div className="ham"></div>
      </div>
      <nav className={`side-nav ${visible}`}>
        <div className="cancel">
          <div className="c-c" onClick={noside}>
            <img src="cross.svg" alt="" className="cross" />
          </div>
        </div>
        <button className={link} onClick={() => changePage("home")}>
          home. ?
        </button>
        <button className={link} onClick={() => changePage("works")}>
          project. ?
        </button>
        <button className={link} onClick={() => changePage("contact")}>
          contact. ?
        </button>
        <button className={link} onClick={() => changePage("about")}>
          about. ?
        </button>
      </nav>
    </header>
  );
}

export default Header;
