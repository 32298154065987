import { Http2ServerRequest } from 'http2';
import React from 'react';
import Header from './assets/header.tsx';
import Footer from './Footer.tsx';

function Contact({page}) {
    return ( 
        <>
        <div className="wrapper">
            <Header page={page}/>
            <div className="form-wrap">
                <div className="form">
                    <label htmlFor="name">
                        Name:
                    <input type="text" id='name' placeholder='john doe' />
                    </label>
                    <label htmlFor="email">
                        Email:
                    <input type="email"  id='email' placeholder='chinedu@gmail.com'/>
                    </label>
                    <label htmlFor="message">
                        Message:
                    <textarea name="" id="message" cols="30" rows="10" placeholder='message'></textarea>
                    </label>
                    <div className="btn-wrap">

                    <button className="btn sub">
                        Message
                    </button>
                    </div>
                </div>
            <div className="contact">
                
                <div className="c-email">
                    <h2 className="em">
                        Email:
                    </h2>
                    <p className="c-em c-p">
                        edchi2track@gmail.com
                    </p>
                </div>
                <div className="c-tel">
                    <h2 className="tel">
                        Tel:
                    </h2>
                    <p className="c-t c-p">
                        09032672139
                    </p>
                </div>
            </div>
            </div>
        </div>  
            <Footer/>
        </>
     );
}

export default Contact;