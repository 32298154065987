import React from "react";
import Header from "./assets/header.tsx";
import { useState } from "react";
function Works({ page }) {
  const [loader, setLoader] = useState({
    zIndex: 30,
    display: "flex",
  });

  const handleLoader = () => {
    setLoader({
      zIndex: -30,
      display: "none",
    });
  };

  return (
    <div onLoad={handleLoader} className="works-cover">
      <div style={loader} className="loader">
        <h1>Loading....</h1>
      </div>
      <Header page={page} />
      <div className="w-wrapper">
        <h3 className="h-work">Projects</h3>
        <div className="row">
          <div className="fr"></div>
          <div className="sr"></div>
        </div>
        <div className="t-works">
          <div className="e-work">
            <a
              href="https://keytradeinv.netlify.app"
              target="_blank"
              rel="noreferrer"
              className="a-img"
            >
              <div className="w-img" id="img-4"></div>
              <div className="w-write">
                <h3>keytrade</h3>
                <p className="skill-tags">
                  <span className="tag">React</span>
                  <span className="tag">Node</span>
                  <span className="tag">Material UI</span>
                </p>
              </div>
            </a>
          </div>
          <div className="e-work">
            <a
              href="https://www.ndbenzautos.com"
              target="_blank"
              rel="noreferrer"
              className="a-img"
            >
              <div className="w-img" id="img-3"></div>
              <div className="w-write">
                <h3>Nd Benz autos</h3>
                <p className="skill-tags">
                  <span className="tag">Nextjs</span>
                  <span className="tag">Node</span>
                  <span className="tag">Typescript</span>
                </p>
              </div>
            </a>
          </div>
          <div className="e-work">
            <a
              href="https://metabnb-pied.vercel.app/"
              target="_blank"
              rel="noreferrer"
              className="a-img"
            >
              <div className="w-img" id="img-5"></div>
              <div className="w-write">
                <h3>metabnb</h3>
                <p className="skill-tags">
                  <span className="tag">React</span>
                  <span className="tag">Css</span>
                </p>
              </div>
            </a>
          </div>
          <div className="e-work">
            <a
              href="https://geegpay-jsin.vercel.app/"
              target="_blank"
              rel="noreferrer"
              className="a-img"
            >
              <div className="w-img" id="img-1"></div>
              <div className="w-write">
                <h3>geeg Dashboard</h3>
                <p className="skill-tags">
                  <span className="tag">React</span>
                  <span className="tag">Css</span>
                </p>
              </div>
            </a>
          </div>
          <div className="e-work">
            <a
              href="https://jobsnitoc.netlify.app"
              target="_blank"
              rel="noreferrer"
              className="a-img"
            >
              <div className="w-img" id="img-2"></div>
              <div className="w-write">
                <h3>employable</h3>
                <p className="skill-tags">
                  <span className="tag">React</span>
                  <span className="tag">Css</span>
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;
