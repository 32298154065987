import "../styles/App.css";
import React, { useEffect } from "react";
import FirstPage from "./FirstPage.tsx";
import About from "./About.tsx";
import Contact from "./Contact.tsx";
import { useState } from "react";
import Works from "./Works.tsx";

function App() {
  const [page, setpage] = useState("home");
  const [loader, setLoader] = useState({
    zIndex: 30,
    display: "flex",
  });
  const handlePage = (data: string): void => {
    setpage(data);
  };
  const handleLoader = () => {
    setLoader({
      zIndex: -30,
      display: "none",
    });
  };
  useEffect(() => {
    console.log(page);
    console.log(loader);
  });

  return (
    <div onLoad={handleLoader} className="App">
      <div style={loader} className="loader">
        <h1>Loading....</h1>
      </div>
      <div className="topline"> </div>
      {page === "home" && <FirstPage page={handlePage} />}
      <div className="bottomline"></div>
      {page === "about" && <About page={handlePage} />}
      {page === "works" && <Works page={handlePage} />}
      {page === "contact" && <Contact page={handlePage} />}
    </div>
  );
}

export default App;
