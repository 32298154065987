import React from "react";
import ParticlesBg from "particles-bg";
import Header from "./assets/header.tsx";
import Footer from "./Footer.tsx";

function Project({ page }) {
  return (
    <>
      <div className="project" id="project">
        <Header page={page} />
        <div className="p-cover" id="p-cover">
          <ParticlesBg type="random" bg={true} />
          <div className="download-icon-container">
            <a
              href="Mbaodoh_Chinedu_cv.pdf"
              target="_blank"
              className="download-link"
            >
              <img
                src="./downloadIcon.svg"
                alt=" resume"
                className="download-icon"
              />
            </a>
          </div>
          <div className="t-wrap">
            <div className="p-text" id="p-text">
              <h4 className="about">About</h4>
              <p className="txt">
                Chinedu Mbaodoh is a visionary software developer hailing from
                Enugu State, Nigeria. Chinedu is driven by a profound mission to
                contribute to a world where individuals lead happier lives
                through the transformative power of technology. One of his
                noteworthy accomplishments is the development of a sophisticated
                ticketing system during his tenure at his previous company. This
                system not only streamlined internal processes but also played a
                pivotal role in tracking and addressing customer complaints,
                showcasing his commitment to enhancing user experiences and
                satisfaction. Chinedu's dedication to leveraging software
                solutions for positive impact defines his professional journey
                in the dynamic realm of technology.
              </p>
            </div>
            <div className="p-image">
              <div className="in-im"></div>
              <div className="p-img"></div>
            </div>
          </div>
          <div className="resume"></div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Project;
