import React from 'react'
function Footer() {
    return ( 
        <div className="footer" id="footer">
            <div className="copy">
                &copy; nitoc Tech 2022
            </div>
            
        </div>
     );
}

export default Footer;